import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    faqCategory:[]
}

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        faqCategoryStore: (state, action) => {
            state.faqCategory = action.payload
        },
    }
})

export const {faqCategoryStore} = commonSlice.actions
export default commonSlice.reducer