import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./reducer/AuthReducer";
import CommonReducer from "./reducer/CommonReducer";
import ChatStore from "./reducer/ChatStore";

const persistConfig = {
    key: 'root', // The key to use in localStorage
    storage, // Use the storage engine (e.g., localStorage)
    whitelist: ['auth','common','chatStore']
};


const reducers = combineReducers({
    auth: AuthReducer,
    common:CommonReducer,
    chatStore:ChatStore
})
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
})
const persistor = persistStore(store);

export { store, persistor };