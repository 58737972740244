import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    userAdminSideData: [],
    activeChatDetails :{}
}

export const chatSlice = createSlice({
    name: 'chatStore',
    initialState,
    reducers: {
        sidebarDataStore: (state, action) => {
            state.userAdminSideData = action.payload
        },
        otherUserDataStore: (state, action) => {
            state.activeChatDetails = action.payload
        },
        setInitialState: (state, action) => {
            return initialState
        }
    }
})

export const { sidebarDataStore,otherUserDataStore,setInitialState } = chatSlice.actions
export default chatSlice.reducer